import axios from "axios";
import GlobalContext from "./GlobalContext";

class WepAPI
{
    static contextType = GlobalContext;

    constructor()
    {
        this.JWT     = null;
        this.site_id = null;
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    setJWT(jwt)
    {
        this.JWT = jwt;
    }

    setSiteID(site_id)
    {
        this.site_id = site_id;
    }

    post(url, params)
    {
        if (typeof params == 'undefined')
        {
            params      = {};
            params.data = {};
        }
        if(!this.JWT){
            this.setJWT(this.getCookie('authToken'));
        }
        if(!this.site_id){
            this.setSiteID(this.getCookie('currentSiteID'));
        }
        params.credentials = 'include';
        params.headers     = {
            'X-Auth-JWT': this.JWT,
            'X-Site-ID' : this.site_id
        };
        const api          = axios.create({
            'headers': params.headers
        });
        api.post(process.env.REACT_APP_API_URL + url, params.data).then(result =>
            {
                if (result.status === 200)
                {
                    if (result.data.error === false)
                    {
                        if (typeof params.onSuccess != 'undefined')
                        {
                            params.onSuccess(result);
                        }
                    } else
                    {
                        if (typeof params.onError != 'undefined')
                            params.onError(result);
                    }
                } else
                {
                    if (typeof params.onFail != 'undefined')
                        params.onFail();
                }
            }
        ).catch(e =>
        {
            if (typeof params.onFail != 'undefined')
                params.onFail();
        });
    }

    get(url, params)
    {
        if (typeof params == 'undefined')
        {
            params      = {};
            params.data = {};

        }
        if(!this.JWT){
            this.setJWT(this.getCookie('authToken'));
        }
        if(!this.site_id){
            this.setSiteID(this.getCookie('currentSiteID'));
        }

        params.credentials = 'include';
        params.headers     = {
            'X-Auth-JWT': this.JWT,
            'X-Site-ID' : this.site_id
        };

        const api = axios.create({
            'headers': params.headers
        });
        api.get(process.env.REACT_APP_API_URL + url, params.data).then(result =>
            {
                if (result.status === 200)
                {
                    if (result.data.error === false || typeof (result.data.error) === 'undefined')
                    {
                        if (typeof params.onSuccess != 'undefined')
                        {
                            params.onSuccess(result);
                        }
                    } else
                    {
                        if (typeof params.onError != 'undefined')
                            params.onError(result);
                    }
                } else
                {
                    if (typeof params.onFail != 'undefined')
                        params.onFail();
                }
            }
        ).catch(e =>
        {
            if (typeof params.onFail != 'undefined')
                params.onFail();
        });
    }
}

export default WepAPI;
