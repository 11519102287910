import React from "react";
import {DataView} from "primereact/dataview";
import iphone from "../Images/iphone.jpeg";
import axios from "axios";

class WidgetPurchasedList extends React.Component
{

    itemTemplate(app, layout)
    {
        if (app.cached_app !== null)
        {
            if (layout === 'list')
            {
                return (
                    <div className="p-grid child">
                        <div style={{float: `left`, width: `25%`}}><img className="device-img app-img" width={`64`} height={`64`} src={app.image}/></div>
                        <div style={{float: `left`, width: `75%`}}><h4 style={{marginBottom: 0}}>{app.cached_app.app_name}</h4>
                            {app.cached_app.artist_title}<br/>
                            version : {app.cached_app.version}
                        </div>
                    </div>
                );
            }
            if (layout === 'grid')
            {
                return (
                    <div className="p-col-12 p-md-3">
                        <div><img className="device-img" width={`64`} height={`64`} src={iphone}/></div>
                        <div><h4>{app.app_name}</h4>{app.sn}</div>
                    </div>
                );
            }
        }
        return (
            <>
            </>
        );
    }


    render()
    {
        if (this.props.items.length > 0)
        {
            return (
                <div className="card card-w-title">
                    <h3>Recent Purchases</h3>
                    <DataView className="devices-list" value={this.props.items} layout="list" itemTemplate={this.itemTemplate}/>
                </div>
            )
        }
        return (
            <div className="card card-w-title">
                <h3>Recent Purchases</h3>
            </div>
        )
    }
}

export default WidgetPurchasedList;