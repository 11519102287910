import React from "react";
import CounterWidget from '../Components/CounterWidget';
import GlobalContext from "../Services/GlobalContext";
import {faMobileAlt, faDollarSign, faTh} from '@fortawesome/free-solid-svg-icons'
import WidgetPurchasedList from "../Components/WidgetPurchasedList";


class AdminPanel extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
        this.updateState = this.updateState.bind(this);
        this.updateSiteID = () => {
            let object = this;
            this.context.WebAPI.get('dashboard', {
                onSuccess: function (result) {
                    object.updateState({
                        isLoaded: true,
                        items: result.data
                    });
                },
                onError: function (result) {
                }
            });
        };
    }

    updateState(state) {
        this.setState(state);
    }

    componentDidMount() {
        this.context.App.addOnSiteChanged(this.updateSiteID);
        let object = this;
        this.context.WebAPI.get('dashboard', {
            onSuccess: function (result) {
                object.updateState({
                    isLoaded: true,
                    items: result.data
                });
            },
            onError: function (result) {
            }
        });
    }

    getUDIDError() {
        if (!this.context.isUDID) {
            return (
                <div className={"col-md-12 alert alert-info"} style={{color:'black',textAlign:'center'}}>You are logged using login/pass, so AppStore is unavailable for you.<br/>PLease
                    use UDID/Pin for accessing to AppStore</div>
            );
        }
        return null;
    }

    getDeviceError() {
        if (this.state.items.devices < 1) {
            return (
                <div className={"col-md-12 alert alert-danger h4 text-center"}>
                    Please contact your Administrator to have your device added to this system.
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="p-grid p-fluid dashboard">
                {this.getUDIDError()}
                {this.getDeviceError()}
                {this.state.isLoaded ? (
                    <React.Fragment>
                        <CounterWidget title="Devices" font={faMobileAlt} detail="" class="bg-info"
                                       count={this.state.items.devices}/>
                        <CounterWidget title="APP's Purchased" font={faTh} detail="" class="bg-green"
                                       count={this.state.items.appscount}/>
                        <CounterWidget title="Balance Owed" font={faDollarSign} detail="" class="bg-danger"
                                       count={this.state.items.balance}/>
                        <WidgetPurchasedList title="Recent Purchases" items={this.state.items.apps}/>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <CounterWidget title="Devices" font={faMobileAlt} detail="" class="bg-info" count={0}/>
                        <CounterWidget title="APP's Purchased" font={faTh} detail="" class="bg-green" count={0}/>
                        <CounterWidget title="Balance Owed" font={faDollarSign} detail="" class="bg-danger" count={0}/>
                        <WidgetPurchasedList title="Recent Purchases" items={[]}/>
                    </React.Fragment>
                )}
            </div>
        )
    }

}

export default AdminPanel;

