import {InputText} from "primereact/inputtext";
import React from "react";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import GlobalContext from "../Services/GlobalContext";

class LoginUdid extends React.Component
{
    static contextType = GlobalContext;

    constructor(props)
    {
        super(props);
        this.state = {pinCode: '', udid: props.udid, errorMessage: '', attempt: 1};
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        let object = this;
        this.context.WebAPI.post('user/get-attempt', {
            data: {
                attempt: object.state.attempt,
            },
            onSuccess: function (result) {
                object.updateState({
                    error: result.error ? result.error : '',
                    attempt: result.data.attempt,
                });
            },
            onError: function (result) {
                object.updateState({
                    error: result.error ? result.error : '',
                });
            }
        });
    }

    updateState(state) {
        this.setState(state);
    }

    render()
    {
        return (
            <div className="container">
                <div className="row center-block">
                    <div className="col-sm-4 col-md-4">
                    </div>
                    <div className="center-block col-sm-4 col-md-4">
                        <div className="card">
                            <div className="card-header">
                                Auth required
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <Password feedback={false} placeholder="Access PIN" className="form-control" value={this.state.pinCode} onChange={e =>
                                    {
                                        this.setState({pinCode: e.target.value});
                                    }}/>
                                </div>
                                <span className="error-message" style={{color: 'red'}}>
                                    {this.state.errorMessage}
                                </span>
                                <div className="form-group">
                                    <Button label="Login" className="form-control p-button-secondary" onClick={this.login}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                    </div>
                </div>
            </div>
        );
    }


    login()
    {
        let udid = this.state.udid;
        let pinCode = this.state.pinCode;
        let object = this;
        this.context.WebAPI.post('user/udid/auth', {
            data     : {
                udid: udid,
                pinCode: pinCode,
                attempt: sessionStorage.getItem('attempt') ? sessionStorage.getItem('attempt') : object.state.attempt
            },
            onSuccess: function (result)
            {
                object.props.onLogin(result.data.token, true);
                localStorage.setItem('deviceSN', JSON.stringify(result.data.deviceSN));
                localStorage.setItem('userName', JSON.stringify(result.data.userName));
                localStorage.setItem('user', JSON.stringify(result.data.user));
                sessionStorage.setItem('attempt', '0');
            },
            onError  : function (result)
            {
                let attempt = sessionStorage.getItem('attempt') ? sessionStorage.getItem('attempt') : object.state.attempt;
                attempt = Number(attempt) + 1;
                sessionStorage.setItem('attempt', attempt);
                if (attempt > 5){
                    attempt = 6;
                    sessionStorage.setItem('attempt', attempt);
                }
                object.updateState({
                    attempt: attempt,
                    errorMessage: result.data.message ? result.data.message : 'Network error'
                });
            },
            onFail   : function ()
            {
                object.setState({errorMessage: 'Network error'});
            }
        });
    }
}

export default LoginUdid;
