import React from 'react';
import WebAPI from '../Services/WebAPI';
import axios from "axios";
import AppCard from "./AppCard";
import {Dropdown} from "primereact/dropdown";

class AppList extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state       = {items: [], content: '', order: 'trackName:asc',};
        this.items       = [];
        this.content     = '';
        this.api         = new WebAPI();
        this.order = [
            { value: 'trackName:asc', label: 'App Name \u2193'},
            { value: 'trackName:desc', label: 'App Name \u2191'},
            { value: 'trackId:asc', label: 'App Id \u2193'},
            { value: 'trackId:desc', label: 'App Id \u2191'},
            { value: 'price:asc', label: 'App Cost \u2193'},
            { value: 'price:desc', label: 'App Cost \u2191'},
        ];
        this.onOrderChange = this.onOrderChange.bind(this)
        this.updateItems = this.updateItems.bind(this);
    }

    updateItems(items)
    {
        const self = this;
        for (var index in items)
        {
            this.api.get('appstore/itunes?id='+items[index].app_id, {
                onSuccess: function (result)
                {
                    const data = result.data;
                    if (data.resultCount > 0)
                    {
                        self.addItemToRender(data.results[0]);
                        self.onOrderChange({value: 'trackName:asc'})
                    }
                }
            });
        }
    }

    renderApp() {
        let i = 0
        return this.state.items.map( (element) => {
            return <AppCard key={i++} item={element} />
        } );
    }

    addItemToRender(item)
    {
        this.items.push(item);
        this.setState({
            items: this.items
        })
    }

    componentDidMount()
    {
        const home = this;
        this.api.get('appstore/apps/' + this.props.appsType, {
            onSuccess: function (result)
            {
                home.updateItems(result.data);
            }
        });
    }

    onOrderChange(e) {
        this.setState({ order: e.value });
        let tmpItems = this.items;
        this.items = [];
        let order = e.value.split(':');
        tmpItems.sort(function(a, b) {
            if (order[0] === 'trackName') {
                if (order[1] === 'asc') {
                    if (a[order[0]].toLowerCase() > b[order[0]].toLowerCase()) {
                        return 1;
                    }
                    if (a[order[0]].toLowerCase() < b[order[0]].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                } else if (order[1] === 'desc') {
                    if (a[order[0]].toLowerCase() < b[order[0]].toLowerCase()) {
                        return 1;
                    }
                    if (a[order[0]].toLowerCase() > b[order[0]].toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
            } else if(order[0] === 'trackId') {
                if (order[1] === 'asc') {
                    return parseFloat(a[order[0]]) - parseFloat(b[order[0]]);
                } else if (order[1] === 'desc') {
                    return parseFloat(b[order[0]]) - parseFloat(a[order[0]]);
                }
                return 0;
            } else if (order[0] === 'price') {
                if (order[1] === 'asc') {
                    return a[order[0]] - b[order[0]];
                } else if (order[1] === 'desc') {
                    return b[order[0]] - a[order[0]];
                }
                return 0;
            }
        })
        for (let index in tmpItems) {
            this.addItemToRender(tmpItems[index]);
        }
    }

    render()
    {
        return (
            <div>
                <div>
                    <Dropdown value={this.state.order} options={this.order} onChange={this.onOrderChange} optionLabel="label" />
                </div>
                <div>
                    {this.state.items.length !== 0 ? this.renderApp() : ''}
                </div>
            </div>
        );
    }
}

export default AppList;
