import React from "react";
import GlobalContext from "../Services/GlobalContext";
import {Multiselect} from 'multiselect-react-dropdown';
import {InputTextarea} from "primereact/inputtextarea";

export default class MassMessaging extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedGroups: [],
            groups: [],
            users: [],
            selectedUsers: [],
            isLoaded: false,
            message: '',
            isSent: false,
            flashMessage: ''
        };
        this.messageText = '';
        this.messageLength = 0;
        this.messageCount = 0;
        this.inputClass = '';
        this.updateState = this.updateState.bind(this);
        this.send = this.send.bind(this);
        this.multiselectRef = React.createRef();
        this.ascii = true;
        this.maxLengthGroup = '';
        this.unicodeStr = '';
    }

    updateState(state) {
        this.setState(state);
    }

    componentDidMount() {
        const object = this;
        this.context.WebAPI.get('groups', {
            onSuccess: function (result) {
                object.updateState({
                    isLoaded: true,
                    groups: result.data.groups,
                    users: result.data.users_to_groups
                });
            },
            onError: function (result) {
            }
        });
    }

    isASCII(message='') {
        message = message ? message : this.messageText;
        this.ascii = /^[\x00-\x7F]*$/.test(message);
        if (!this.ascii) {
            return (<span>Non-ASCII character used.</span>);
        }
        return ('');
    }

    filterMessage() {
        let message = this.messageText;
        let re = /[\u2018|\u2019]/g;
        message = message.replace(re, "\u0027");
        let re2 = /[\u201C|\u201D]/g;
        this.messageText = message.replace(re2, "\u0022");

        return this.messageText;
    }

    checkAll() {
        let all = document.getElementById('recipients_all');
        if(all){
            let recipients = Array.from(document.getElementsByName('recipients[]'));

            if (all.checked === false){
                for (let i = 0, len = recipients.length; i < len; i++) {
                    recipients[i].checked = false;
                }
            } else {
                for (let i = 0, len = recipients.length; i < len; i++) {
                    recipients[i].checked = true;
                }
            }
        }
    }

    checkOne(e) {
        let el = document.getElementById(e.target.id);
        if (el.checked){
            el.checked = true;
        } else {
            el.checked = false;
        }
        return false;
    }

    //
    // showUnicode() {
    //     let str = this.messageText;
    //     let output = "";
    //     for (let i = 0; i < str.length; i++) {
    //         output += "\\u" + ('000' + str.charCodeAt(i).toString(16)).substr(-4) + " ";
    //     }
    //     this.unicodeStr = output.trim();
    // }

    getMessageCount() {
        let firstMessageLength = 160;
        let messageLength = 140;
        let groupTxt = this.maxLengthGroup + ': ';
        this.filterMessage();
        // this.showUnicode();
        let message = this.messageText;
        message = message + groupTxt;
        this.isASCII(message)
        this.messageLength = message.length;

        if (!this.ascii) {
            messageLength = firstMessageLength = 70;
        }
        if (this.messageLength === 0) {
            this.messageCount = 0;
            this.inputClass = '';
        } else if (this.messageLength <= firstMessageLength) {
            this.inputClass = '';
            this.messageCount = 1;
        } else {
            this.inputClass = 'mm_bg_focus';
            this.messageCount = Math.floor((this.messageLength - 1) / messageLength + 1);
        }
    }

    getUsersTable() {
        if (this.state.selectedUsers.length > 0) {
            let table = this.state.selectedUsers.map((item) => {
                return (
                    <tr key={item.recipient_id}>
                        <td><input onClick={ (e) => this.checkOne(e) }
                                 type='checkbox' id={`z`+item.recipient_id} value={item.recipient_id} name={'recipients[]'}
                                   defaultChecked={true}/></td>
                        <td>{item.first_name} {item.last_name}</td>
                        <td>{item.phone_number}</td>
                    </tr>
                );
            });

            return (
                <div className="row">
                    <div className="col-md-12 table-responsive" style={{paddingTop: '15px'}}>
                        <table id="userselecting" style={{width: `100%`}} className={'dataTable no-footer'}>
                            <thead>
                            <tr>
                                <th style={{padding: `8px 10px`, cursor: `pointer`}}><input
                                  onClick={ (e) => this.checkAll(e) }
                                   type='checkbox' value="1" name="recipients_all"
                                           id="recipients_all" defaultChecked={true}/></th>
                                <th>User Name</th>
                                <th>Phone Number</th>
                            </tr>
                            </thead>
                            <tbody>
                            {table}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    send() {
        let object = this;
        let recipients = Array.from(document.getElementsByName('recipients[]')).map((item) => {
            if (item.checked)
                return item.value;
            return false;
        });
        recipients = recipients.filter(function (item) {
            return !!item;
        });

        let groups = this.state.selectedGroups.map((item) => {
            if (item.group_id !== null)
                return item.id
        });
        let message = this.state.message;
        if (message === '') {
            alert('Enter message');
            return;
        }
        if (groups.length === 0) {
            alert('Select groups');
            return;
        }
        if (recipients.length === 0) {
            alert("Select recipients");
            return;
        }
        this.context.WebAPI.post('massmessaging', {
            data: {
                recipients: recipients,
                send_message: groups,
                message: message
            },
            onSuccess: function () {
                object.clearState();
            }
        });
    }

    clearState() {
        this.multiselectRef.current.resetSelectedValues();
        this.setState({isSent: true, selectedGroups: [], selectedUsers: [], message: ''});
    }

    getFlash() {
        if (this.state.isSent) {
            return (
                <div className="alert alert-success" role="alert">
                    SMS was successfully sent!
                </div>
            )
        }
    }

    render() {
        if (this.state.isLoaded) {
            const options = this.state.groups.map(function (item) {
                return {
                    name: item.group_name,
                    id: item.id
                };
            });
            return (
                <div className="row">
                    <div className="col-lg-12">
                        {this.getFlash()}
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-7">
                                        <InputTextarea rows={5} placeholder="Message" className={"form-control " + this.inputClass}
                                            value={this.state.message} onChange={e => {
                                                this.messageLength = e.target.value.length;
                                                this.messageText = e.target.value;
                                                this.getMessageCount();
                                                this.setState({message: this.messageText});
                                            }} autoResize/>
                                    </div>
                                    <div className="col-md-5">
                                        <button className="btn btn-raised btn-warning mr-10"
                                                style={{marginTop: `0px`}} onClick={this.send}>Send
                                        </button>
                                        <br/><br/>
                                        <div style={{fontWeight: `bold`}}>Characters Per Segment</div>
                                        <table>
                                            <tbody>
                                            <tr id="firstMessageLength" style={{ display: (this.ascii ? 'block' : 'none') }}>
                                                <td>160 or less total:</td>
                                                <td style={{fontWeight: `bold`}}>160</td>
                                            </tr>
                                            <tr id="messageLength" style={{ display: (this.ascii ? 'block' : 'none') }}>
                                                <td>Greater than 160 total:</td>
                                                <td style={{fontWeight: `bold`}}>140</td>
                                            </tr>
                                            <tr id="nonASCIImessageLength" style={{ display: (!this.ascii ? 'block' : 'none') }}>
                                                <td>Any total length:</td>
                                                <td style={{fontWeight: `bold`}}>70</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Message length and "group name: " length: <span>{this.messageLength}</span></p>
                                        <p>Number of Messages: <span>{this.messageCount}</span></p>
                                    </div>
                                </div>
                                <div>
                                    <p style={{color: `red`}}>{this.isASCII()}</p>
                                </div>

                                <hr/>
                                <div className="row" style={{paddingTop: `15px`}}>
                                    <div className="col-md-12">
                                        <label htmlFor="send_message">Select Group:</label>
                                        <Multiselect options={options} displayValue="name"
                                                     onSelect={(list, item) => {
                                                         let selectedUsers = [];
                                                         const users = this.state.users;
                                                         let maxLength = 0;

                                                         list.map((item) => {
                                                             if (item.name.length > maxLength){
                                                                 maxLength = item.name.length;
                                                                 this.maxLengthGroup = item.name;
                                                             }

                                                             Object.values(users[item.id]).map((item) => {
                                                                 selectedUsers[item.recipient_id] = item;
                                                             });
                                                         });

                                                         this.setState({
                                                             selectedGroups: list,
                                                             selectedUsers: selectedUsers
                                                         });
                                                     }}
                                                     onRemove={(list, item) => {
                                                         let selectedUsers = [];
                                                         const users = this.state.users;
                                                         let maxLength = 0;

                                                         list.map((item) => {
                                                             if (item.name.length > maxLength){
                                                                 maxLength = item.name.length;
                                                                 this.maxLengthGroup = item.name;
                                                             }

                                                             Object.values(users[item.id]).map((item) => {
                                                                 selectedUsers[item.recipient_id] = item;
                                                             });
                                                         });
                                                         this.setState({
                                                             selectedGroups: list,
                                                             selectedUsers: selectedUsers
                                                         });
                                                     }}
                                                     ref={this.multiselectRef}/>
                                    </div>
                                </div>
                                {this.getUsersTable()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>Loading</div>
            )
        }
    }
}
