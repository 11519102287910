import React from "react";
import './layout/layout.css';
import './material/css/datatables.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';
import './material/css/bootstrap-material-design.css';
import './material/css/bootstrap.min.css';
import './material/css/jquery.dropdown.css';
import './material/css/ripples.css';
import './material/css/style.css';
import jwt_decode from "jwt-decode";
import Login from "./Pages/Login";
import LoginUdid from "./Pages/LoginUdid"
import AdminPanel from "./Pages/AdminPanel";
import classNames from "classnames";
import {AppTopbar} from "./AppTopbar";
import {AppMenu} from "./AppMenu";
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {Route} from "react-router-dom";
import MyDevices from "./Pages/MyDevices";
import {AppProfile} from "./AppProfile";
import {GlobalProvider} from './Services/GlobalContext';
import WebAPI from "./Services/WebAPI";
import AppList from "./Components/AppList";
import PageTitle from "./Components/PageTitle";
import AppDescription from "./Components/Apps/AppDescription";
import MassMessaging from "./Pages/MassMessaging";

class App extends React.Component {
    constructor(props) {
        super(props);
        require('dotenv').config();
        this.login = (token, isUDID) => {
            if (typeof isUDID === 'undefined')
                isUDID = false;
            this.state.WebAPI.setJWT(token);
            this.getPermissions(token);
            let today = new Date();
            let expire = new Date();
            expire.setTime(today.getTime() + 3600000*24*14);
            document.cookie = "authToken=" + token + "; path=/;" + "; expires="+expire.toGMTString();
            document.cookie = "currentUDID=" + isUDID + "; path=/;" + "; expires="+expire.toGMTString();;
            this.setState({isLogged: true, isUDID: isUDID});
            this.getAvailableSites();
            this.createMenu();
            window.location.reload();
        };
        this.logout = () => {
            this.setState({isLogged: false, isUDID: false});
            document.cookie = "authToken=" + "; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie = "currentUDID=" + "; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        };

        const token = this.getCookie('authToken');
        const siteId = this.getCookie('currentSiteID');
        const isUdid = this.getCookie('currentUDID');
        this.sites = [];
        this.onSiteChangedEvents = [];
        this.state = {
            isLogged: !!token,
            isUDID: isUdid,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: true,
            mobileMenuActive: false,
            WebAPI: new WebAPI(),
            onLogin: this.login,
            onLogout: this.logout,
            jwtToken: token,
            siteID: siteId,
            App: this,
            sites: this.sites,
            permissions: null,
            user: null,
            menu: []
        };
        if (!!token) {
            this.state.WebAPI.setJWT(token);
        }
        if (!!siteId) {
            this.state.WebAPI.setSiteID(siteId);
        }
        this.setTimeZone();

        this.addMenu = this.addMenu.bind(this);
        this.setPermissions = this.setPermissions.bind(this);
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.changeSite = this.changeSite.bind(this);
        this.createMenu = this.createMenu.bind(this);
        if (token) {
            this.getAvailableSites();
            this.createMenu();
        }
    }

    setTimeZone(){
        let timeZone = sessionStorage.getItem('timeZone');
        if (!timeZone) {
            const newTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            sessionStorage.setItem('timeZone', newTimeZone);
            this.state.WebAPI.post('user/set-timezone', {
                data     : {
                    timeZone: newTimeZone
                },
                onSuccess: function (result)
                {
                    document.location.reload();
                }
            });
        }
    }

    addOnSiteChanged(e) {
        this.onSiteChangedEvents.push(e);
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    getPermissions(token) {
        let vm = this,
            data = jwt_decode(token),
            user_id = data.user_id,
            site_id = this.getCookie('currentSiteID'),
            request_params = '';

        if (site_id) {
            request_params += 'site_id=' + site_id;
        }

        this.state.WebAPI.get('user/perm/' + user_id + '?'+ request_params, {
            onSuccess: function (result) {
               vm.setPermissions(result.data);
               vm.createMenu();
               vm.addMenu(this.menu);
            }
        });
    }

    setPermissions(permissions) {
        this.setState({
            permissions: permissions
        })
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            } else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        } else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    addSites(sites) {
        this.setState({sites: sites});
    }

    addMenu(menu) {
        this.setState({menu: menu})
    }

    changeSite(e) {
        let val = e.code;

        this.state.WebAPI.setSiteID(val);
        if (e.code){
            document.cookie = "currentSiteID=" + val;
        }
        if (e.id){
            document.cookie = "currentSiteID=" + e.id;
        }
        if (e.name){
            document.cookie = "siteSelected=" + e.name;
        }
        this.setState({siteID: val});
        for (var i in this.onSiteChangedEvents) {
            this.onSiteChangedEvents[i]();
        }
        this.getPermissions(this.state.jwtToken)
    }

    getAvailableSites() {
        const app = this;
        this.state.WebAPI.get('sites/available', {
            onSuccess: function (result) {
                app.sites = result.data.sites;
                app.addSites(result.data.sites);
                app.changeSite(result.data.sites[0]);
            }
        });
    }

    createMenu() {
        this.menu = [];
        this.menu.push({label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'});
        this.menu.push({label: 'My Devices', icon: 'pi pi-fw pi-cog', to: '/mydevices'});
        if (this.state.permissions !== null && this.state.permissions.mm_permission === true) {
            this.menu.push({label: 'Mass Messaging', icon: 'pi pi-fw pi-cog', to: '/massmessaging'});
        }
        if (this.state.isUDID !== false && this.state.isUDID !== 'false') {
            this.menu.push(
                {
                    label: 'App Store', icon: 'pi pi-fw pi-apple', items: [
                        {
                            label: 'Featured Apps', to: '/appstore'
                        },
                        {
                            label: 'Paid Apps', to: '/paidapps'
                        },
                        {
                            label: 'Free Apps', to: '/freeapps'
                        },
                        {
                            label: 'Purchased Apps', to: '/purchased'
                        }
                    ]
                })
        }
        // this.addMenu(this.menu);
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    getUdid() {
        let url = new URL(window.location.href);
        return url.searchParams.get('udid');
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getUserName() {
        return JSON.parse(localStorage.getItem('userName'));
    }

    getDeviceSN() {
        return JSON.parse(localStorage.getItem('deviceSN'));
    }

    render() {
        if (this.state.isLogged === false) {
            if (this.getUdid() === null) {
                return (
                    <GlobalProvider value={this.state}>
                        <Login onLogin={this.login}/>
                    </GlobalProvider>
                );
            } else {
                return (
                    <GlobalProvider value={this.state}>
                        <LoginUdid udid={this.getUdid()} onLogin={this.login}/>
                    </GlobalProvider>
                );
            }
        } else {
            const wrapperClass = classNames('layout-wrapper', {
                'layout-overlay': this.state.layoutMode === 'overlay',
                'layout-static': this.state.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.state.mobileMenuActive
            });

            const sidebarClassName = classNames("layout-sidebar", {
                'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
                'layout-sidebar-light': this.state.layoutColorMode === 'light'
            });

            return (
                <GlobalProvider value={this.state}>
                    <div className={wrapperClass} onClick={this.onWrapperClick}>
                        <AppTopbar onToggleMenu={this.onToggleMenu} onUserClick={this.logout}/>
                        <Router>
                            <div ref={(el) => this.sidebar = el} className={sidebarClassName}
                                 onClick={this.onSidebarClick}>
                                <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick}/>
                            </div>

                            <div className="layout-main">
                                <Route path="/" exact component={AdminPanel}/>
                                <Route path="/mydevices" exact component={MyDevices}/>
                                <Route path="/profile" exact component={AppProfile}/>
                                {
                                    this.state.permissions !== null && this.state.permissions.mm_permission &&
                                    <Route path="/massmessaging" exact component={MassMessaging}/>
                                }
                                <Route exact path='/appstore' render={(props) => (
                                    <>
                                        <PageTitle title={'Featured Apps'}/>
                                        <AppList appsType={'featured'}/>
                                    </>
                                )}/>
                                <Route path='/paidapps' render={(props) => (
                                    <>
                                        <PageTitle title={'Paid Apps'}/>
                                        <AppList appsType={'paid'}/>
                                    </>
                                )}/>
                                <Route path='/freeapps' render={(props) => (
                                    <>
                                        <PageTitle title={'Free Apps'}/>
                                        <AppList appsType={'free'}/>
                                    </>
                                )}/>
                                <Route path='/purchased' render={(props) => (
                                    <>
                                        <PageTitle title={'Purchased Apps'}/>
                                        <AppList appsType={'purchased'}/>
                                    </>
                                )}/>
                                <Route path='/app/:trackId' component={AppDescription}/>
                            </div>
                        </Router>
                        <div className="layout-mask"></div>
                    </div>
                </GlobalProvider>
            )
        }
    }
}

export default App;

