import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'primereact/dropdown';
import GlobalContext from "./Services/GlobalContext";
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import UserInfo from "./Components/UserInfo";

export class AppTopbar extends Component {

    static contextType = GlobalContext;

    static defaultProps = {
        onToggleMenu: null,
        onUserClick: null
    };

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired,
        onUserClick: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        let siteSelected = this.getCookie('siteSelected');
        this.state = {site: 1, siteSelected: siteSelected !== null ? siteSelected : 'Select a Site'};
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    componentDidUpdate() {
        let siteSelected = this.getCookie('siteSelected');
        let site = this.getCookie('currentSiteID');
        this.state.site = site;
        this.state.siteSelected = siteSelected;
    }

    render() {
        let items = [];
        const profilemenu = [
            {
                label: 'Profile',
                icon: 'pi pi-user',
                command: () => {
                    window.location.href = '/profile';
                }
            },
            {
                label: 'Logout',
                icon: 'pi pi-times',
                command: () => {
                    this.context.App.logout();
                }
            }
        ];
        for (var i in this.context.sites) {
            items.push({key: i, name: this.context.sites[i].name, code: this.context.sites[i].id});
        }
        if (items.length > 0) {
            return (
                <>
                    <Menu model={profilemenu} popup={true} ref={el => this.menu = el} id="popup_menu"/>
                    <div className="layout-topbar clearfix">
                        <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                            <span className="pi pi-bars"/>
                        </button>

                        <div className="layout-topbar-icons">
                            <button className="p-link" onClick={(event) => {
                                this.menu.toggle(event)
                            }} aria-controls="popup_menu" aria-haspopup={true}>
                                <span className="layout-topbar-item-text">User</span>
                                <span className="layout-topbar-icon pi pi-user"></span>
                            </button>
                            <UserInfo/>
                            <Dropdown optionLabel="name" options={items} onChange={(e) => {
                                this.setState({site: e.value})
                                this.context.App.changeSite(e.value);
                            }} placeholder={this.state.siteSelected} value={this.state.site}/>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <Menu model={profilemenu} popup={true} ref={el => this.menu = el} id="popup_menu"/>
                <div className="layout-topbar clearfix">
                    <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                        <span className="pi pi-bars"/>
                    </button>
                    <div className="layout-topbar-icons">
                        <button className="p-link" onClick={(event) => {
                            this.menu.toggle(event)
                        }} aria-controls="popup_menu" aria-haspopup={true}>
                            <span className="layout-topbar-item-text">User</span>
                            <span className="layout-topbar-icon pi pi-user"></span>
                        </button>
                    </div>
                </div>
            </>
        );
    }
}
