import React, { Component } from 'react';
import classNames from 'classnames';
import GlobalContext from "./Services/GlobalContext";
import WebAPI from "./Services/WebAPI";

export class AppProfile extends React.Component {

    static contextType = GlobalContext;

    constructor() {
        super();
        this.state = {
            expanded: false,
            WebAPI: new WebAPI(),
            userName: null,
            userPhone: null,
            userEmail: null
        };
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        let user = this.context.App.getUser();
        if (typeof user != "undefined" && user != null) {
            this.setState({
                userName: user.name,
                userPhone: user.phone,
                userEmail: user.email,
            });
        }
    }

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        return  (
            <div className="layout-profile">
                <div>
                    <img src="assets/layout/images/profile.png" alt="" />
                </div>
                <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{this.state.userName}</span>

                </button>
                <div>Tel: <a href={'tel:' + this.state.userPhone}>{this.state.userPhone}</a></div>
                <div>E-mail: <a href={'mailto:' + this.state.userEmail}>{this.state.userEmail}</a></div>
                <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                    <li><button className="p-link"><i className="pi pi-fw pi-user"/><span>Account</span></button></li>
                    <li><button className="p-link"><i className="pi pi-fw pi-inbox"/><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    <li><button className="p-link"><i className="pi pi-fw pi-power-off"/><span>Logout</span></button></li>
                </ul>
            </div>
        );
    }
}
