import React from "react";
import axios from "axios";
import WebAPI from "../../Services/WebAPI";
import GlobalContext from "../../Services/GlobalContext";

class AppStore extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            trackId: null,
            item: null,
            priceButtonText: '',
            priceButton: null,
        };

        this.api = new WebAPI();
        this.setPurchased = this.setPurchased.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    updateState(state) {
        this.setState(state)
    }
    componentDidMount() {
        this.setState({trackId: this.props.trackId, item: this.props.item});
        this.setPriceButton(this.props.trackId, this.props.item);
    }

    setPurchased() {
        this.setState({priceButtonText: 'Requested'});
    }

    sendRequest() {
        let app = this;
        this.context.WebAPI.post('notifications/add/app_purchase', {
            data: {'app_id': this.state.item.trackId},
            onSuccess: function () {
                app.setPurchased();
            }
        });
    }

    setPriceButton(id, item) {
        let vm = this;
        this.context.WebAPI.get('appstore/app/purchase/' + item.trackId, {
            onSuccess: function (result) {
                if (result.data != '') {
                    vm.updatePriceButton('purchased', null)

                } else {
                    if (item.price === 0) {
                        vm.updatePriceButton('free', item);
                    } else {
                        vm.updatePriceButton('buy', item)
                    }
                }
            },
            onError: function (result) {
            }
        });
    }

    buyApp() {
        let vm = this;
        this.context.WebAPI.post('appstore/app/buy', {
            data: {'app_id': this.state.item.trackId},
            onSuccess: function (result) {
                if (result.data.error) {
                    vm.updatePriceButton('error', null)
                }
                if (result.data != '') {
                    vm.updatePriceButton('complete_purchase', null)
                    vm.props.appPurchasedEvent();
                } else {
                    vm.updatePriceButton('buy', false, this.state.item.trackId)
                }
            },
            onError: function (result) {
                console.log(result)
                vm.updatePriceButton('error', null)
            }
        });
    }

    disableButton() {
        document.getElementById('buyButton').setAttribute('disabled', 'disabled');
    }

    updatePriceButton(type, item) {
        if (type === 'purchased') {
            this.updateState({
                priceButton: (<button className="btn btn-raised btn-block btn-warning" type="submit">Already
                    Purchased</button>)
            })
        } else if (type === 'confirm') {
            let name = item.price === 0 ? 'Confirm?' : 'Confirm Purchase?';
            this.updateState({
                priceButton: (
                    <button onClick={e => {
                        this.disableButton();
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.sendRequest(e.target);
                        this.buyApp(e.target);
                    }} id="buyButton" className="btn btn-raised btn-block btn-warning " type="submit">{name}</button>
                )
            })
        } else if (type === 'free') {
            this.updateState({
                priceButton: (
                    <div id="form-content">
                        <form method="post" id="form" autoComplete="off">
                            <input name="post_type" type="hidden" value="purchase"/>
                            <input type="hidden" name="id" id="id" value={item.trackId}/>
                            <button onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                this.updatePriceButton('confirm', item);
                            }} className="btn btn-raised btn-block btn-warning">
                                {this.state.priceButtonText === '' ? item.formattedPrice : this.state.priceButtonText}
                            </button>
                        </form>
                    </div>
                )
            })
        } else if (type === 'buy') {

            this.updateState({
                priceButton: (
                    <div id="form-content">
                        <form method="post" id="form" autoComplete="off">
                            <input name="post_type" type="hidden" value="purchase"/>
                            <input type="hidden" name="id" id="id" value={item.trackId}/>
                            <button onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                this.updatePriceButton('confirm', item);
                            }} className="btn btn-raised btn-block btn-warning">
                                Buy Now
                                - {this.state.priceButtonText === '' ? item.formattedPrice : this.state.priceButtonText}
                            </button>
                        </form>
                    </div>
                )
            });
        } else if (type === 'complete_purchase') {
            this.updateState({
                priceButton: (
                    <button className="btn btn-raised btn-block btn-warning">Purchase Complete</button>
                )
            });
        } else if (type === 'error') {
            this.updateState({
                priceButton: (
                    <button className="btn btn-raised btn-block btn-danger">Purchase Failed, Try Re-opening App</button>
                )
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.item !== null ? (
                    <div>
                        {this.state.priceButton !== null ? this.state.priceButton : ''}
                    </div>
                ) : (
                    <div>
                    </div>
                )}
            </div>
        );
    }
}

export default AppStore
