import React from "react";
import {NavLink} from "react-router-dom";

class AppCard extends React.Component {
    constructor(props) {
        super(props);

        let starsRating = 5 / props.item.averageUserRating;
        starsRating     = 160 / starsRating;
        this.styleStar = {
            width: starsRating + 'px'
        };
    }

    slugify(str) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return str.toString().toLowerCase()
            .replace(/\s+/g, '-')
            .replace(p, c => b.charAt(a.indexOf(c)))
            .replace(/&/g, '-and-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '')
    }

    render() {
        return (
            <div className="col-md-6 p-0">
                <div className="featbox box1">
                    <div className="featimage">
                        <a href={this.props.item.trackViewUrl}>
                            <img src={this.props.item.artworkUrl512} width="180px" height="180px" alt=""
                                 className="d-inline"/>
                        </a>
                        <div className="feat-rating">
                            <div className="score">
                                <span>Score: {this.props.item.averageUserRating}</span>
                            </div>
                            <div className="bigstarbox">
                                <span className="bigstars">
                                    <span style={this.styleStar}></span>
                                </span>
                            </div>
                            <div className="scorecount">
                                <span>From {this.props.item.userRatingCount} Ratings</span>
                            </div>
                        </div>
                    </div>
                    <h3>{this.props.item.trackCensoredName}</h3>
                    <h4>{this.props.item.sellerName}</h4>
                    <ul className="p-0 list-unstyled">
                        <li><b>Release Date:</b>{(new Date(this.props.item.releaseDate).toDateString())}</li>
                        <li><b>Category:</b> {this.props.item.primaryGenreName}</li>
                    </ul>
                    <p>{this.props.item.description.substr(0, 350)}</p>
                    <NavLink to={{pathname: '/app/' + this.props.item.trackId, state: {item: this.props.item}}} className="btn btn-raised btn-warning mr-10">Read More</NavLink>
                </div>
            </div>
        );
    }
}

export default AppCard;
