import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CounterWidget extends React.Component
{
    render()
    {
        return (
            <div className="p-col-12 p-lg-4">
                <div className="card widget">
                    <div className="row row-table row-flush">
                        <div className={"p-col-4 icon-size text-center " + this.props.class}>
                            <FontAwesomeIcon icon={this.props.font} />
                        </div>
                        <div className="p-col-8">
                            <div className="text-center">
                                <h4 className="mt0">{this.props.count}</h4>
                                <p className="mb0 text-muted">{this.props.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CounterWidget;
