import React from "react";
import GlobalContext from "../Services/GlobalContext";
import {DataView} from "primereact/dataview";
import iphone from '../Images/iphone.jpeg';

class MyDevices extends React.Component
{
    static contextType = GlobalContext;

    constructor(props)
    {
        super(props);
        this.state        = {
            isLoaded: false,
            items   : [],
            layout  : 'list'
        };
        this.updateState  = this.updateState.bind(this);
        this.updateSiteID = () =>
        {
            let object = this;
            this.context.WebAPI.get('devices/my', {
                onSuccess: function (result)
                {
                    object.updateState({
                        isLoaded: true,
                        items   : result.data.devices
                    });
                },
                onError  : function (result)
                {
                }
            });
        };
    }

    updateState(state)
    {
        this.setState(state);
    }

    componentDidMount()
    {
        this.context.App.addOnSiteChanged(this.updateSiteID);
        let object = this;
        this.context.WebAPI.get('devices/my', {
            onSuccess: function (result)
            {
                object.updateState({
                    isLoaded: true,
                    items   : result.data.devices
                });
            },
            onError  : function (result)
            {
            }
        });
    }

    itemTemplate(device, layout)
    {
        if (layout === 'list')
        {
            return (
                <div className="p-grid child">
                    <div><img className="device-img" width={`64`} height={`64`} src={iphone}/></div>
                    <div><h4>{device.device_name}</h4>
                        {device.sn}<br />
                        user: {device.user.first_name} {device.user.last_name}<br />
                        udid: {device.udid}<br />
                    </div>
                </div>
            );
        }
        if (layout === 'grid')
        {
            return (
                <div className="p-col-12 p-md-3">
                    <div><img className="device-img" width={`64`} height={`64`} src={iphone}/></div>
                    <div><h4>{device.device_name}</h4>{device.sn}</div>
                </div>
            );
        }
    }

    render()
    {
        return (
            <>
                <DataView className="devices-list" value={this.state.items} layout={this.state.layout} itemTemplate={this.itemTemplate}/>
            </>
        )
    }
}

export default MyDevices;
