import React from "react";
import GlobalContext from "../Services/GlobalContext";

class userInfo extends React.Component
{
    static contextType = GlobalContext;

    constructor()
    {
        super();
        this.state = {
            userName: null,
            deviceSN: null
        };
    }

    componentDidMount() {
        this.setState({
            userName: this.context.App.getDeviceSN(),
            deviceSN: this.context.App.getUserName(),
        });
    }

    render()
    {
        return (
            <div className={'user-info'}>
                <div>{this.state.deviceSN}</div>
                <div>{this.state.userName}</div>
            </div>
        )
    }
}

export default userInfo;