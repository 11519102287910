import React from "react";
import WebAPI from "../../Services/WebAPI";
import axios from "axios";
import GlobalContext from "../../Services/GlobalContext";
import AppStore from "./AppStore";
import { useState, useEffect } from 'react'

const PurchasedMessage = ({appPurchasedFlag}) => {
    const [show, setShow] = useState(true);

    if (!show || !appPurchasedFlag) {
        return null;
    }

    return (
        <div className="alert alert-success bootstrap" role="alert">
            The app you just purchased should be available for installation within a few minutes from
            Self Service. If the app isn’t available in Self Service, refresh the list by swiping downward.
        </div>
    )
}

class AppDescription extends React.Component
{
    static contextType = GlobalContext;

    constructor(props)
    {
        super(props);
        this.state = {
            trackId        : null,
            item: null,
            priceButtonText: '',
            priceButton: null,
            appPurchasedFlag: false
        };

        this.api          = new WebAPI();
        this.updateState = this.updateState.bind(this);
        this.appPurchasedEvent = this.appPurchasedEvent.bind(this);
    }

    updateState(state) {
        this.setState(state)
    }
    appPurchasedEvent(){
        this.setState({appPurchasedFlag: true});
    }
    componentDidMount()
    {
        if (this.props.location.state != undefined)
        {
            this.setState({trackId: this.props.match.params.trackId, item: this.props.location.state.item});
        } else
        {
            this.setState({trackId: this.props.match.params.trackId, item: null});
            this.getApp(this.props.match.params.trackId);
        }
    }

    getApp(trackId)
    {
        const self = this;
        this.api.get('appstore/itunes?id='+trackId, {
            onSuccess: function (result)
            {
                const data = result.data;
                if (data.resultCount > 0)
                {
                    self.setState({
                        item: data.results[0]
                    })
                }
            }
        });
    }

    slugify(str)
    {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return str.toString().toLowerCase()
                  .replace(/\s+/g, '-')
                  .replace(p, c => b.charAt(a.indexOf(c)))
                  .replace(/&/g, '-and-')
                  .replace(/[^\w\-]+/g, '')
                  .replace(/\-\-+/g, '-')
                  .replace(/^-+/, '')
                  .replace(/-+$/, '')
    }

    getStyleStar()
    {
        let starsRating = 5 / this.state.item.averageUserRating;
        starsRating     = 160 / starsRating;
        return {
            width: starsRating + 'px'
        };
    }

    number_format(number, decimals = 0, dec_point = '.', thousands_sep = ',')
    {

        let sign = number < 0 ? '-' : '';

        let s_number = Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + "";
        let len      = s_number.length;
        let tchunk   = len > 3 ? len % 3 : 0;

        let ch_first = (tchunk ? s_number.substr(0, tchunk) + thousands_sep : '');
        let ch_rest  = s_number.substr(tchunk)
                               .replace(/(\d\d\d)(?=\d)/g, '$1' + thousands_sep);
        let ch_last  = decimals ?
            dec_point + (Math.abs(number) - s_number)
                .toFixed(decimals)
                .slice(2) : '';

        return sign + ch_first + ch_rest + ch_last;
    }

    getSellerUrl()
    {
        if (this.state.item.sellerUrl != undefined && this.state.item.sellerUrl == true)
        {
            return '<a href="' + this.state.item.sellerUrl + '" target="_blank" rel="nofollow">' + this.state.item.artistName + '</a>';
        } else
        {
            return this.state.item.artistName;
        }
    }

    formatSizeUnits(bytes)
    {
        if (bytes >= 1073741824)
        {
            bytes = this.number_format(bytes / 1073741824, 2) + ' GB';
        } else if (bytes >= 1048576)
        {
            bytes = this.number_format(bytes / 1048576, 2) + ' MB';
        } else if (bytes >= 1024)
        {
            bytes = this.number_format(bytes / 1024, 2) + ' kB';
        } else if (bytes > 1)
        {
            bytes = bytes + ' bytes';
        } else if (bytes == 1)
        {
            bytes = bytes + ' byte';
        } else
        {
            bytes = '0 bytes';
        }

        return bytes;
    }

    getScreenshots()
    {
        let i = 0;
        return this.state.item.screenshotUrls.map((element) =>
        {
            return (<a className="fancybox" rel="group" key={i++} href={element}><img src={element}/></a>);
        });
    }

    render()
    {
        return (
            <div>
                {this.state.item !== null ? (
                    <div>
                        <PurchasedMessage appPurchasedFlag={this.state.appPurchasedFlag} />
                        <div className="headpage">
                            <div className="container">
                                <div className="headpageimage">
                                    <img src={this.state.item.artworkUrl100.replace('100x100bb.jpg', '200x200bb.jpg')}
                                         alt={this.state.item.trackName} height="200px" width="200px"/>
                                </div>
                                <div className="headpageinfo">
                                    <h1 className="product-title">{this.state.item.trackName}</h1>
                                    <h2 className="product-stock">By {this.state.item.artistName}</h2>
                                    <ul>
                                        <li><b>Category:</b> <a href={process.env.REACT_APP_HOST + '/category/' + this.state.item.primaryGenreId + '/' + this.slugify(this.state.item.primaryGenreName)}>
                                            {this.state.item.primaryGenreName}
                                        </a>
                                        </li>
                                        <li>
                                            <b>Release Date:</b> {this.state.item.releaseDate.substr(0, 10)}
                                        </li>
                                        <li><b>Current Version:</b> {this.state.item.version}
                                        </li>
                                        <li>
                                            <b>Adult Rating:</b> {this.state.item.contentAdvisoryRating}
                                        </li>
                                        <li>
                                            <b>File Size:</b> {this.formatSizeUnits(this.state.item.fileSizeBytes)}
                                        </li>

                                        <li><b>Developer:</b> {this.getSellerUrl()}</li>
                                        <li><b>Compatibility:</b> Requires iOS {this.state.item.minimumOsVersion}
                                        </li>
                                    </ul>
                                </div>
                                <div className="headpageright">
                                    {(this.state.item.averageUserRating != undefined && this.state.item.averageUserRating != '') ?
                                        (
                                            <div className="product-rating">
                                                <div className="score">
                                                    <span>Score: {this.state.item.averageUserRating}</span>
                                                </div>
                                                <div className="bigstarbox">
                                                    <span className="bigstars">
                                                        <span style={this.getStyleStar()}></span>
                                                    </span>
                                                </div>
                                                <div className="scorecount">
                                                    <span>From {this.state.item.userRatingCount} Ratings</span>
                                                </div>
                                            </div>
                                        ) : ('')
                                    }
                                    <div className="postactions">
                                        <AppStore trackId={this.state.trackId} item={this.state.item}
                                                  appPurchasedEvent={this.appPurchasedEvent} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="headpage mt-4">
                            <div className="postmain">
                                <div className="postmaintitle">
                                    <h3>Description</h3>
                                </div>
                                <div className="postmaindescr">
                                    <p>
                                        {this.state.item.description}
                                    </p>
                                </div>
                            </div>
                            <div className="postmain">
                                <div className="postmaintitle">
                                    <h3>Screenshots</h3>
                                </div>
                                <div className="postscreens">
                                    {this.getScreenshots()}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                    </div>
                )}
            </div>
        );
    }
}

export default AppDescription
